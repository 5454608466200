import React from "react";
import { ThemeProvider } from "styled-components";
import Sticky from "react-stickynode";
import { hostingTheme } from "common/src/theme/hosting";
import {
  GlobalStyle,
  ContentWrapper,
} from "../containers/Hosting/hosting.style";
import { ResetCSS } from "common/src/assets/css/style";
import Navbar from "../containers/Hosting/Navbar";
import BannerSectionOther from "../containers/Hosting/Banner/BannerOther/Discovery";
import InfoOtherSection from "../containers/Hosting/InfoOther/Discovery";
import Footer from "../containers/Hosting/Footer";
import { DrawerProvider } from "common/src/contexts/DrawerContext";
import { ParallaxProvider } from "react-scroll-parallax";
import SEO from "../components/seo";
import TopRibbon from "../containers/Hosting/TopRibbon";
export default () => {
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Discovery"
          description="Leverage the power of RAPFlow and RAPBot for automation, virtually across all the processes in your organization. Build your own AI solution here."
        />

        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="webinarpage">
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSectionOther />
          <InfoOtherSection />
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};
