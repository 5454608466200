import React from "react";
import PropTypes from "prop-types";
import Box from "common/src/components/Box";
import Text from "common/src/components/Text";
import Heading from "common/src/components/Heading";
import Container from "common/src/components/UI/Container";
import BannerWrapper from "../../banner.style";

const BannerSectionOther = ({
  row,
  title,
  title2,
  description,
  subdescription,
  button,
  textArea,
}) => {
  return (
    <>
      <BannerWrapper className="banner_container banner_bg Webinar">
        <Container>
          <Box {...row}>
            <Box {...textArea}>
              {/* <Text {...title} content="Discovery Call" /> */}
              <Heading
                {...description}
                content="Get a Headstart with your Hyperautomation Journey"
              />
            </Box>
          </Box>
        </Container>
      </BannerWrapper>
    </>
  );
};

BannerSectionOther.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  title2: PropTypes.object,
  description: PropTypes.object,
  subdescription: PropTypes.object,
  button: PropTypes.object,
};

BannerSectionOther.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  textArea: {
    width: [1, "95%", "95%", "90%", "90%"],
  },
  title: {
    fontSize: ["30px", "30px", "30px", "30px", "30px"],
    fontWeight: "normal",
    // color: '#0f2137',
    color: "#fff",
    letterSpacing: "-0.025em",
    mt: "25px",
    mb: "25px",
    lineHeight: "1.31",
    textAlign: "center",
  },
  title2: {
    fontSize: ["26px", "32px", "35px", "35px", "35px"],
    fontWeight: "normal",
    // color: '#0f2137',
    color: "#fff",
    letterSpacing: "-0.025em",
    mt: "25px",
    mb: "25px",
    lineHeight: "1.31",
    textAlign: "center",
  },
  description: {
    fontSize: ["26px", "32px", "35px", "35px", "35px"],
    // color: '#343d48cc',
    color: "#fff",
    lineHeight: "1.2",
    // mb: ['8px', '8px', '10px', '10px', '10px'],
    mt: "25px",
    mb: "25px",
    textAlign: "center",
  },
  subdescription: {
    fontSize: ["14px", "14px", "14px", "16px", "16px"],
    // color: '#343d48cc',
    color: "#fff",
    lineHeight: "1.75",
    mt: "25px",
    mb: "25px",
    textAlign: "center",
  },
  button: {
    title: "Search",
    type: "button",
    fontSize: "18px",
    fontWeight: "500",
    color: "#fff",
    pl: "22px",
    pr: "22px",
    colors: "primaryWithBg",
    iconPosition: "left",
  },
};

export default BannerSectionOther;
