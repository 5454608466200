import React, { useEffect } from 'react';

export const CalendlyLink = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div>
      <div
        className="calendly-inline-widget"
        data-url="https://outlook.office365.com/book/Raghus30minmeeting1@rap.ventures"
        style={{ minWidth: '320px', height: '650px' }}
      ></div>
      <div className="empty_space30" />
      <div className="empty_space20" />
    </div>
  );
};
